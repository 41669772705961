
import { ComponentPublicInstance, defineComponent, ref } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { validateForm } from '@/utils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { userState } from '@/store/user.store';
import { showToast } from '@/hooks/useToast';

export default defineComponent({
  name: 'Password',
  components: {
    'ui-text-input': UITextInput,
    'ui-button': UIButton,
  },
  setup() {
    const password = ref('');
    const user = userState;
    const isLoading = ref(false);
    const passwordRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const changePassword = async() => {
      try {
        isLoading.value = true;
        if (validateForm([passwordRef])) {
          const body = { password: password.value };
          const request = await makeRequest(METHODS.PATCH, `auth/password/${user.value?._id}`, true, body);
          if (request.ok) {
            showToast('SUCCESS', 'Contraseña actualizada');
            password.value = '';
          } else {
            showToast('ERROR', request.errors+'');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      isLoading,
      passwordRef,
      changePassword,
      password
    };
  }
});
